import React        from "react"
import {NewsLayout} from "sections/News/"
import useNewsData  from "shared/Hooks/News/useNewsData"
import {Images}     from "src/sections/News/NewsLayout/Images";
import {List}       from "src/sections/News/NewsLayout/List";
import {TextBlock}  from "src/sections/News/NewsLayout/TextBlock";
import {NEWS_8}     from "types/News"

const Article = () => {
  const data = useNewsData(NEWS_8);

  return (
    <NewsLayout data={data}>
      <List data={data.lists.first}/>
      <TextBlock data={data.texts.first}/>
      <Images data={data.images.first}/>
    </NewsLayout>
  )
}

export default Article;
